import React from "react"
import { Row } from "antd"
import { graphql } from "gatsby"

import Shell from "../components/Shell/Shell"
import Meta from "../components/Meta/Meta"
import SharpHero from "../components/SharpHero/SharpHero"
import BlogList from "../components/BlogList/BlogList"

export default ({ data }) => {
  const { hero } = data

  const meta = {
    seo: {
      title: "Blog | Lakeside Lodge Clemson",
      img: hero.localFile.childImageSharp.fluid.src,
      imgAlt: hero.description,
    },
    uri: "/blog/",
  }

  const heroProps = {
    caption: `Blog`,
    contentfulAsset: hero,
    height: `40vh`,
    imageStyle: {
      filter: `brightness(0.75)`,
    },
    pictureStyle: {
      objectPosition: `center 20%`,
    },
  }

  const row = {
    align: "top",
    justify: "center",
    type: "flex",
    style: {
      margin: `auto`,
      maxWidth: 1360,
    },
  }

  return (
    <Shell>
      <Meta {...meta} />
      <SharpHero {...heroProps} />
      <Row {...row}>
        <BlogList />
      </Row>
    </Shell>
  )
}

export const query = graphql`
  query {
    hero: contentfulAsset(title: { eq: "LLC - Death Valley" }) {
      description
      localFile {
        childImageSharp {
          fluid(
            jpegProgressive: true
            jpegQuality: 80
            maxWidth: 1500
            webpQuality: 80
          ) {
            aspectRatio
            base64
            sizes
            src
            srcSet
            srcSetWebp
          }
        }
      }
    }
  }
`
